import { integrationsSelector } from "src/store/plaidIntegration/selector";
import { poshmarkSyncWithCheck } from "./poshmark";
import { mercariSyncWithCheck } from "./mercari";
import { integrationSyncEntrySelector } from "../selector";

export * from "./actions";

export function syncWithCheck(integrationId: string) {
  return (dispatch, getState) => {
    const integrations = integrationsSelector(getState());
    const integration = integrations.find((i) => i.id === integrationId);
    if (integration) {
      const status = integrationSyncEntrySelector(getState(), integration.type);
      if (status) return;

      if (integration.type === "poshmark")
        dispatch(poshmarkSyncWithCheck(integrationId));
      else if (integration.type === "mercari")
        dispatch(mercariSyncWithCheck(integrationId));
    }
  };
}
