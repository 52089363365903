import produce from "immer";
import { handleActions } from "redux-actions";

import { secondsToDate } from "../../utils";

import { TransactionState } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { LOG_OUT } from "../system/actions";
import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  DELETE_ITEMS,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  RESET,
  TRANSACTION,
  SET_FILTER,
  SELECT_ITEMS,
  SET_PAGE_SIZE,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
  CLEAR_UPDATE_METHOD,
} from "../common";

const DEFAULT_ACCOUNTS = [
  { "General Ledger Account": "Shipping Supplies", Type: "Other COGS" },
  { "General Ledger Account": "Shipping Fees", Type: "Other COGS" },
  { "General Ledger Account": "Sourcing Mileage/Transportation", Type: "Other COGS" },
  { "General Ledger Account": "Listing/Platform Fees", Type: "Other COGS" },
  { "General Ledger Account": "Office Supplies", Type: "Other Business Costs" },
  { "General Ledger Account": "Donations", Type: "Other Business Costs" },
  { "General Ledger Account": "Non-Sourcing Mileage/Transportation", Type: "Other Business Costs" },
  { "General Ledger Account": "Travel", Type: "Other Business Costs" },
  { "General Ledger Account": "Cell Phone", Type: "Other Business Costs" },
  { "General Ledger Account": "Internet", Type: "Other Business Costs" },
  { "General Ledger Account": "Rent", Type: "Other Business Costs" },
  { "General Ledger Account": "Utilities", Type: "Other Business Costs" },
  { "General Ledger Account": "Bank Fees", Type: "Other Business Costs" },
  { "General Ledger Account": "Subscriptions/Dues", Type: "Other Business Costs" },
  { "General Ledger Account": "Equipment", Type: "Other Business Costs" },
  { "General Ledger Account": "Car Expenses", Type: "Other Business Costs" },
  { "General Ledger Account": "Contract Work", Type: "Other Business Costs" },
  { "General Ledger Account": "Ask Accountant", Type: "Other Business Costs" },
  {
    "General Ledger Account": "Inventory Purchases",
    Type: "Other COGS",
    inventoryType: "cash",
  },
];

const initialState: TransactionState = {
  items: [],
  total: 0,
  start: 1,
  end: 1,
  limit: 25,

  method: METHOD_TYPE.LIST,
  loading: false,
  loaded: false,
  error: null,
  message: "",

  accounts: DEFAULT_ACCOUNTS,
  loadingAccounts: false,

  selected: [],
  filter: {
    items: [],
  },
};

export const transactionReducer = handleActions<TransactionState, any>(
  {
    [TRANSACTION + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [TRANSACTION + FETCH_ITEMS + START]: (state, { payload: { start } }) =>
      produce(state, (draft) => {
        draft.start = start || initialState.start;
        draft.method = METHOD_TYPE.LIST;
        draft.loading = true;
        draft.error = null;
      }),
    [TRANSACTION + FETCH_ITEMS + SUCCESS]: (
      state,
      { payload: { data, length, total } }
    ) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.items = data.map((e: any) => ({
          ...e,
          date: e.date ? secondsToDate(e.date) : undefined,
        })); // state.items.concat(data);
        draft.total = total;
        draft.error = null;
      }),
    [TRANSACTION + FETCH_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [TRANSACTION + DELETE_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE_ALL;
        draft.loading = true;
        draft.loaded = false;
      }),
    [TRANSACTION + DELETE_ITEMS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.items = [];
      }),
    [TRANSACTION + DELETE_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [TRANSACTION + CREATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.CREATE;
        draft.loading = true;
        draft.error = null;
        draft.loaded = false;
      }),
    [TRANSACTION + CREATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.error = null;
      }),
    [TRANSACTION + CREATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [TRANSACTION + UPDATE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.UPDATE;
        draft.loading = true;
        draft.error = null;
      }),
    [TRANSACTION + UPDATE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.map((item) =>
          item.id === payload.id ? payload : item
        );
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [TRANSACTION + UPDATE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),

    [TRANSACTION + DELETE_ITEM + START]: (state) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.DELETE;
        draft.loading = true;
        draft.error = null;
      }),
    [TRANSACTION + DELETE_ITEM + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter((item) => item.id !== payload);
        draft.loading = false;
        draft.loaded = false;
        draft.error = null;
      }),
    [TRANSACTION + DELETE_ITEM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [TRANSACTION + SELECT_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [TRANSACTION + SET_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter = payload;
      }),
    [TRANSACTION + SET_PAGE_SIZE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.limit = payload;
      }),
    [CLEAR_UPDATE_METHOD]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.method = METHOD_TYPE.LIST;
      }),
    [TRANSACTION + FS_CREATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        payload?.sort((a, b) => {
          const legend = [-1, 0, 1];
          return legend[+((a?.date as Date) < (b?.date as Date))];
        });
        draft.items.unshift(...payload.map((e: any) => ({
          ...e,
          date: e.date ? secondsToDate(e.date) : undefined,
        })));
        draft.loading = false;
        draft.loaded = true;
      }),
    [TRANSACTION + FS_UPDATE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        console.log('updated:', { payload });
        draft.items.forEach((item) => {
          const updated = payload.find((newItem) => newItem.id === item.id);
          if (updated) {
            const mapped = {
              ...updated,
              date: updated.date ? secondsToDate(updated.date) : undefined,
            };
            Object.assign(item, mapped);
          }
        });
      }),
    [TRANSACTION + FS_DELETE_ITEMS]: (state, { payload }) =>
      produce(state, (draft) => {
        console.log('deleted:', { payload });
        draft.items = state.items.filter((item) => !payload.find((deleted) => deleted.id === item.id));
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);
