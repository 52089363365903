import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

import { StatefulLoadableButton } from "src/components/LoadableButton";
import { featureFlagSelector } from "src/store/system/selector";
import {
  MissingExtensionError,
  OutdatedExtensionError,
} from "src/utils/extension";
import { getMercariUser } from "src/utils/extension/mercari";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";

interface MercariDoYouSellOnMercariProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

type Options = "yes" | "no";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

function MercariDoYouSellOnMercari({
  onClose,
  onStepChange,
}: MercariDoYouSellOnMercariProps) {
  const classes = useStyles();
  const [value, setValue] = useState<Options>();

  const canAmazonIntegration =
    useSelector((state) => featureFlagSelector(state, "amazon")) &&
    process.env.REACT_APP_MODE === "development";

  const isEtsyIntegrationEnabled =
    useSelector((state) => featureFlagSelector(state, "etsy")) &&
    process.env.REACT_APP_MODE === "development";

  return (
    <>
      <DialogTitle onClose={onClose}>Set Up Questions - 4/8</DialogTitle>
      <MuiDialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Do you sell on Mercari?</FormLabel>
          <RadioGroup
            value={value || null}
            onChange={(event) => {
              setValue((event.target as HTMLInputElement).value as Options);
            }}
            className={classes.radioGroup}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onStepChange("poshmark:do-you-sell-on-poshmark");
          }}
          color="primary"
        >
          Go Back
        </Button>
        <StatefulLoadableButton
          variant="contained"
          disabled={!value}
          onClick={async () => {
            const nextStep = [
              canAmazonIntegration && "amazon:do-you-sell-on-amazon",
              isEtsyIntegrationEnabled && "etsy:do-you-sell-on-etsy",
              "other:do-you-sell",
            ].filter(Boolean)[0];

            if (value !== "yes") {
              onStepChange(nextStep as Step);
              return;
            }

            try {
              await getMercariUser();
              onStepChange("mercari:confirm");
            } catch (e) {
              if (e instanceof MissingExtensionError) {
                onStepChange("mercari:add-extension");
                return;
              }

              if (e instanceof OutdatedExtensionError) {
                onStepChange("mercari:update-extension");
                return;
              }

              toast.error((e as Error).message);
            }
          }}
          color="primary"
          autoFocus
        >
          Next
        </StatefulLoadableButton>
      </DialogActions>
    </>
  );
}

export default MercariDoYouSellOnMercari;
