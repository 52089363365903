import firebase from "firebase/app";
import { getUserId } from "src/config";
import firestore from "../firestore";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";

export async function etsyRequestAuthorizationCode(args?: { back: string }) {
  const f = firebase.functions().httpsCallable("etsy-requestAuthorizationCode");
  return await f(args);
}

export async function etsyRequestAccessToken(args: {
  authorizationCode: string;
  state: string;
}) {
  const f = firebase.functions().httpsCallable("etsy-requestAccessToken");
  return await f(args);
}

export async function etsyDeleteIntegration() {
  const f = firebase.functions().httpsCallable("etsy-deleteIntegration");
  return await f();
}

export async function etsyFetchListings() {
  const f = firebase.functions().httpsCallable("etsy-listingImport");
  return await f({});
}

export async function etsyFetchTransactions() {
  const f = firebase.functions().httpsCallable("etsy-transactionImport");
  return await f({});
}

export async function etsySyncApi(integrationId: string) {
  const f = firebase.functions().httpsCallable("etsy-syncNow");
  const result = await f({ integrationId });
  return result.data;
}

export async function etsyIntegrationSyncChange(
  id: string,
  sync: boolean,
  inventory: boolean = true
) {
  const userId = await getUserId();
  const db = firestore();

  await db.runTransaction(async (transaction) => {
    const user = await transaction.get(db.collection("Users").doc(userId));
    await transaction.update(db.collection("Plaid_Integrations").doc(id), {
      sync: sync && user.data()?.plan === UserPlan.ULTIMATE,
      inventory: sync && inventory,
    });
  });
}
