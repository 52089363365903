import Papa from "papaparse";
import moment from "moment";
import memoizeOne from "memoize-one";

import { getUserIdSync } from "src/config/storage";
import { omit } from "lodash";

export const excelDateToJSDate = (value) =>
  new Date(Math.round((value - 25569) * 86400 * 1000));

const convertDate = (value) => {
  if (!value) {
    return null;
  }
  if (!Number(value)) {
    return new Date(new Date(value).setHours(10));
  }
  return new Date(new Date(excelDateToJSDate(value)).setHours(10));
};

const convertNumber = (value) => {
  let returnValue = value;
  if (typeof value === "string") {
    returnValue = +value.replace("$", "").replace(",", "");
  }
  return returnValue;
};

const convertBoolean = (value) =>
  typeof value === "string" &&
  new Set(["true", "yes"]).has(value.toLowerCase());

const ebayTransactionHeaders = [
  "Transaction creation date",
  "Type",
  "Order number",
  "Legacy order ID",
  "Buyer username",
  "Buyer name",
  "Ship to city",
  "Ship to province/region/state",
  "Ship to zip",
  "Ship to country",
  "Net amount",
  "Payout currency",
  "Payout date",
  "Payout ID",
  "Payout method",
  "Payout status",
  "Reason for hold",
  "Item ID",
  "Transaction ID",
  "Item title",
  "Custom label",
  "Quantity",
  "Item subtotal",
  "Shipping and handling",
  "Seller collected tax",
  "eBay collected tax",
  "Final Value Fee - fixed",
  "Final Value Fee - variable",
  'Very high "item not as described" fee',
  "Below standard performance fee",
  "International fee",
  "Gross transaction amount",
  "Transaction currency",
  "Exchange rate",
  "Reference ID",
  "Description",
];

const expectedHeaders = {
  mercariSales: [
    "Completed Date",
    "Item Title",
    "Shipped to State",
    "Item Price",
    "Seller Shipping Fee",
    "Shipping Adjustment Fee",
    "Mercari Selling Fee",
    ["Payment Processing Fee", "Payment Processing Fee Charged To Seller"],
    "Sales Tax Charged to Buyer",
  ],
  mercariInventory: [],
  poshmarkInventory: [
    "Listing Date",
    "Listing Date",
    "Item SKU",
    "Listing Title",
    "Department",
    "Category",
    "Subcategory",
    "Brand",
    "Cost Price",
  ],
  ebaySales: [
    "Transaction creation date",
    "Ship to province/region/state",
    "Item title",
    "Quantity",
    "Custom label",
    "Item subtotal",
    "Shipping and handling",
    "Seller collected tax",
    "eBay collected tax",
    "Final Value Fee - fixed",
    "Final Value Fee - variable",
    'Very high "item not as described" fee',
    "Below standard performance fee",
    "International fee",
  ],
  ebayInventory: ["Title", "Quantity", "Custom label", "Category"],
  mrgSales: [
    "Item Title",
    "SKU",
    "Sale Date",
    "Sale Price",
    "Transaction Fees",
    "Shipping Cost",
    "Sale Platform",
    "Sales Tax",
    "Liable to Pay",
    "Sale State",
  ],
};

const keyTypes = {
  mercariSales: "sales",
  mercariInventory: "inventory",
  poshmarkInventory: "inventory",
  ebaySales: "sales",
  ebayInventory: "inventory",
  ebaySalesDirectImport: "sales",
  ebayInventoryDirectImport: "inventory",
};

export const mrgSalesHeaders = [
  "Item Title",
  "Department",
  "Category",
  "Sub-Category",
  "Brand",
  "Purchase Date",
  "List Date",
  "SKU",
  "Location",
  "Purchase Price",
  "Notes",
  "Sale Date",
  "Sale Price",
  "Shipping Cost",
  "Transaction Fees",
  "Sale Platform",
  "Sales Tax",
  "Liable to Pay",
  "Sale State",
  "Transaction Id",
];
const mrgInventoryHeaders = [
  "Item Title",
  "Department",
  "Category",
  "Sub-Category",
  "Brand",
  "Purchase Date",
  "List Date",
  "SKU",
  "Location",
  "Purchase Price",
  "Platforms Listed",
  "Notes",
];
export const extraRowsHeaders = [
  "General Ledger Account",
  "Amount",
  "Date",
  "Description",
];
export const returnsHeaders = [
  "Return Date",
  "Order Number",
  "Return ID",
  "Return Amount",
  "Fees Returned",
];
const mercariPlaceholderMessage =
  "Purchase Date and Purchase Price are not provided by Mercari, and are placeholder values. To properly account for this item, please update these fields with accurate information.";
const poshmarkPlaceholderMessage =
  "Purchase Date and Purchase Price are not provided by Poshmark, and are placeholder values. To properly account for this item, please update these fields with accurate information.";
const ebayPlaceholderMessage =
  "The purchase price and purchase date are placeholder values. These values are not included in the eBay report, but are very important for proper accounting. Make sure to update these fields for your records.";

const dollarsToNumber = (value) => {
  if (!value) {
    return 0;
  }
  const result = +value.replace("$", "").replace(",", "");
  return result || 0;
};

const janFirst = `01/01/${new Date().getFullYear()}`;

const tryDate = (original) => {
  const date = new Date(original);
  if (isNaN(date.getTime())) {
    return null;
  }
  return moment(original).format("MM/DD/YYYY");
};

const transformMercariDate = (original) => {
  const date = tryDate(original);
  if (date) {
    return date;
  }
  const [year, month, day] = original.split("-");
  return `${month}/${day}/${year}`;
};

const transformEbayDate = (original) => {
  const date = tryDate(original);
  if (date) {
    return date;
  }
  const [day, month, year] = original.split("-");
  const transformedMonth =
    new Date(Date.parse(`${month} 1 2021`)).getMonth() + 1;
  return `${transformedMonth}/${day}/${year}`;
};

const transformEbaySalesDate = (original) => {
  let date;
  try {
    date = transformEbayDate(original);
    if (date) {
      return date;
    }
  } catch {
    date = new Date();
  }
  const [month, day, year] = original.split(" ");
  const transformedMonth =
    new Date(Date.parse(`${month} 1 2021`)).getMonth() + 1;
  const transformedDay = day.replace(",", "");
  return `${transformedMonth}/${transformedDay}/${year}`;
};

export const checkHeaders = (
  key: keyof typeof expectedHeaders,
  headers: string[],
  missing: string[] = []
) => {
  for (const header of expectedHeaders[key]) {
    if (Array.isArray(header)) {
      const hasSomeHeader = header.some((h) => headers.includes(h));
      if (!hasSomeHeader) missing.push(header.join(" or "));
    } else {
      if (!headers.includes(header)) missing.push(header);
    }
  }

  if (missing.length) return false;
  return true;
};

const preprocessors = {
  mrgSales: (rows) => rows.filter((row) => !!row),
  mercariSales: (rows) => rows.slice(0, -2),
  mercariInventory: () => {
    throw new Error("mercariInventory pre processor not implemented");
  },
  poshmarkInventory: (rows: string[]) => rows.slice(6, -1),
  ebaySales: (rows: string[]) => rows.slice(11),
  ebayInventory: (rows: string[]) => rows,
};

const mapEbayBundle = (
  source: any,
  allSources: any[],
  shippingCost: number
) => {
  const bundleItems = allSources.filter(
    (item) =>
      item.Type === "Order" &&
      item["Net amount"] === "--" &&
      item["Order number"] === source["Order number"]
  );
  const totalPrice = +source["Gross transaction amount"];
  const totalSalesTax =
    (+source["Seller collected tax"] || 0) +
    (+source["eBay collected tax"] || 0);
  let remainingShippingCost = shippingCost;
  let remainingSalesTax = totalSalesTax;
  const output = bundleItems.map((item, index) => {
    const mapped = {
      "Sale Date": transformEbaySalesDate(source["Transaction creation date"]),
      "Sale State": item["Ship to province/region/state"],
      "Item Title": `${item["Item title"]}${
        item.Quantity > 1 ? ` - (qty: ${item.Quantity})` : ""
      }`,
      SKU: item["Custom label"] === "--" ? "" : item["Custom label"],
      "Sale Price": +item["Item subtotal"] + +item["Shipping and handling"],
      "Sales Tax": 0,
      "Liable to Pay": item["Seller collected tax"] > 0,
      "Transaction Fees":
        (-item["Final Value Fee - fixed"] || 0) +
        (-item["Final Value Fee - variable"] || 0) +
        (-item['Very high "item not as described" fee'] || 0) +
        (-item["Below standard performance fee"] || 0) +
        (-item["International fee"] || 0),
      "Shipping Cost": 0,
      "Purchase Date": janFirst,
      "Purchase Price": 0,
      "Sale Platform": "eBay",
      Notes: ebayPlaceholderMessage,
      "List Date": "",
      Department: "",
      Category: "",
      "Sub-Category": "",
      Brand: "",
      Location: "",
    };
    if (source["Quantity"] > 1) {
      mapped.Notes = `Sold ${source["Quantity"]} units. ` + mapped.Notes;
    }
    if (remainingShippingCost) {
      mapped["Shipping Cost"] =
        index === bundleItems.length - 1
          ? remainingShippingCost
          : Math.min(
              remainingShippingCost,
              Math.floor(
                (mapped["Sale Price"] / totalPrice) * shippingCost * 100
              ) / 100
            );
      remainingShippingCost -= mapped["Shipping Cost"];
    }
    if (remainingSalesTax) {
      mapped["Sales Tax"] =
        index === bundleItems.length - 1
          ? remainingSalesTax
          : Math.min(
              remainingSalesTax,
              Math.floor(
                (mapped["Sale Price"] / totalPrice) * totalSalesTax * 100
              ) / 100
            );
      remainingSalesTax -= mapped["Sales Tax"];
    }
    return mapped;
  });
  return output;
};

export const daysOnPlatform = (saleDate, listDate) => {
  if (!isNaN(saleDate - listDate)) {
    return Math.floor((saleDate - listDate) / (1000 * 60 * 60 * 24));
  }
  return null;
};

export const forceNull = (value) => {
  if (value === undefined) {
    return null;
  }
  return value;
};

const getEarliestCompletedDate = memoizeOne((sales) => {
  const date = sales.reduce((d, element) => {
    if (element["Order Status"] !== "Completed") return d;
    const current = element["Completed Date"];

    if (!d) return current;
    return d < current ? d : current;
  }, undefined);

  return transformMercariDate(date);
});

const createEtsyDate = (timestamp) => {
  if (!timestamp) {
    return new Date();
  } else {
    const d = new Date(0);
    d.setUTCSeconds(timestamp);
    return moment(d).format("MM/DD/YYYY");
  }
};

export const transforms = {
  mercariSales: (source: any, index: number, sales) => {
    if (source["Order Status"] !== "Completed") {
      return [];
    }
    return [
      {
        "Sale Date": transformMercariDate(source["Completed Date"]),
        "Item Title": source["Item Title"],
        "Sale Price": +source["Item Price"],
        "Shipping Cost":
          +source["Seller Shipping Fee"] + +source["Shipping Adjustment Fee"],
        "Transaction Fees":
          +source["Mercari Selling Fee"] +
          (+source["Payment Processing Fee"] ||
            +source["Payment Processing Fee Charged To Seller"]),
        "Sales Tax": source["Sales Tax Charged to Buyer"],
        "Liable to Pay": "false",
        "Purchase Price": 0,
        "Sale Platform": "Mercari",
        Notes: mercariPlaceholderMessage,
        "List Date": "",
        Department: "",
        Category: "",
        "Sub-Category": "",
        Brand: "",
        SKU: "",
        Location: "",
        "Sale State": source["Shipped to State"],
        "Purchase Date": getEarliestCompletedDate(sales),
        "Transaction Id": source["Item Id"],
      },
    ];
  },
  mercariInventory: () => {
    throw new Error("Invalid transform mercariInventory");
  },
  poshmarkInventory: (source: any) => {
    const output: any[] = [];
    const makeDoc = () => ({
      "Purchase Date": source["Listing Date"],
      "List Date": source["Listing Date"],
      SKU: source["SKU"],
      "Item Title": source["Listing Title"],
      Department: source["Department"],
      Category: source["Category"],
      "Sub-Category": source["Subcategory"],
      Brand: source["Brand"],
      "Purchase Price": dollarsToNumber(source["Cost Price"]),
      Location: "",
      "Platforms Listed": "Poshmark",
      Notes: poshmarkPlaceholderMessage,
    });
    const quantity = source["Quantity Available"] || 0;
    for (let i = 0; i < quantity; i++) {
      output.push(makeDoc());
    }
    return output;
  },
  ebaySales: (source: any, index: number, allSources: any[]) => {
    const shippingItems = allSources.filter(
      (item) =>
        item.Type === "Shipping label" &&
        item["Order number"] === source["Order number"]
    );
    const shippingCost = shippingItems.reduce(
      (result, item) => result - item["Net amount"],
      0
    );
    if (source.Type !== "Order" || source["Net amount"] === "--") {
      return [];
    }
    if (source["Item title"] === "--") {
      return mapEbayBundle(source, allSources, shippingCost);
    }
    const mapped = {
      "Sale Date": transformEbaySalesDate(source["Transaction creation date"]),
      "Sale State": source["Ship to province/region/state"],
      "Item Title": `${source["Item title"]}${
        source.Quantity > 1 ? ` - (qty: ${source.Quantity})` : ""
      }`,
      SKU: source["Custom label"] === "--" ? "" : source["Custom label"],
      "Sale Price": +source["Item subtotal"] + +source["Shipping and handling"],
      "Sales Tax":
        (+source["Seller collected tax"] || 0) +
        (+source["eBay collected tax"] || 0),
      "Liable to Pay": source["Seller collected tax"] > 0,
      "Transaction Fees":
        (-source["Final Value Fee - fixed"] || 0) +
        (-source["Final Value Fee - variable"] || 0) +
        (-source['Very high "item not as described" fee'] || 0) +
        (-source["Below standard performance fee"] || 0) +
        (-source["International fee"] || 0),
      "Shipping Cost": shippingCost,
      "Purchase Date": janFirst,
      "Purchase Price": 0,
      "Sale Platform": "eBay",
      Notes: ebayPlaceholderMessage,
      "List Date": "",
      Department: "",
      Category: "",
      "Sub-Category": "",
      Brand: "",
      Location: "",
      "Platform Sale Id": source["Order Number"],
    };

    if (source["Quantity"] > 1) {
      mapped.Notes = `Sold ${source["Quantity"]} units. ` + mapped.Notes;
    }
    return [mapped];
  },
  ebayInventory: (source: any) => {
    const output: any[] = [];
    const sourceCategorySplit = source.Category.split(" > ");
    const makeDoc = () => ({
      "Item Title": source.Title,
      SKU: source["Custom label"] === "--" ? "" : source["Custom label"],
      Department: sourceCategorySplit[0],
      Category: sourceCategorySplit[1],
      "Sub-Category": sourceCategorySplit[2],
      "Purchase Date": janFirst,
      "Purchase Price": 0,
      "Platforms Listed": "eBay",
      Notes: ebayPlaceholderMessage,
      Brand: "",
      Location: "",
      "List Date": "",
      "Platform Listing Id": source["Item number"],
    });
    const quantity = Math.max(
      0,
      (source.Quantity || 0) - (source.SellingStatus.QuantitySold || 0)
    );
    //TODO: this can be optimized
    for (let i = 0; i < quantity; i++) {
      output.push(makeDoc());
    }
    return output;
  },
  ebayInventoryDirectImport: (source: any) => {
    const output: any[] = [];
    const sourceCategorySplit = source.PrimaryCategory?.CategoryName?.split(
      ":"
    ) || ["", "", ""];
    const listDate = new Date(source?.ListingDetails?.StartTime || 0);
    if (!source.ListingDetails) {
      console.log({ source });
    }
    const makeDoc = () => ({
      "Item Title": source.Title,
      SKU: source.SKU || "",
      Department: sourceCategorySplit[0],
      Category: sourceCategorySplit[1],
      "Sub-Category": sourceCategorySplit[2],
      "Purchase Date": `${
        listDate.getMonth() + 1
      }/${listDate.getDate()}/${listDate.getFullYear()}`,
      "Purchase Price": 0,
      "Platforms Listed": "eBay",
      Notes: ebayPlaceholderMessage,
      Brand: "",
      Location: "",
      "List Date": `${
        listDate.getMonth() + 1
      }/${listDate.getDate()}/${listDate.getFullYear()}`,
      "Platform Listing Id": source.ItemID,
    });
    const quantity = Math.max(
      0,
      (source.Quantity || 0) - (source.SellingStatus.QuantitySold || 0)
    );
    //TODO: this can be optimized
    for (let i = 0; i < quantity; i++) {
      output.push(makeDoc());
    }
    return output;
  },
  ebayInventoryDirectImportV2: (source: any) => {
    const output: any[] = [];
    const quantity = source["QuantityNet"] || source.quantity || 0;
    //TODO: this can be optimized
    for (let i = 0; i < quantity; i++) {
      output.push(omit(source, ["QuantityNet", "quantity"]));
    }
    return output;
  },
  ebaySingleInventoryTransform: (source: any) => {
    const sourceCategorySplit = source.PrimaryCategory?.CategoryName?.split(
      ":"
    ) || ["", "", ""];
    const listDate = new Date(source?.ListingDetails?.StartTime || 0);
    const quantity = Math.max(
      0,
      (source.Quantity || 0) - (source.SellingStatus.QuantitySold || 0)
    );
    return {
      "Item Title": source.Title,
      SKU: source.SKU || "",
      Department: sourceCategorySplit[0],
      Category: sourceCategorySplit[1],
      "Sub-Category": sourceCategorySplit[2],
      "Purchase Date": `${
        listDate.getMonth() + 1
      }/${listDate.getDate()}/${listDate.getFullYear()}`,
      "Purchase Price": 0,
      "Platforms Listed": source.integrationName || "eBay",
      Notes: ebayPlaceholderMessage,
      Brand: source.GetItemResponse?.ProductListingDetails?.BrandMPN?.Brand
        ? `${source.GetItemResponse?.ProductListingDetails?.BrandMPN?.Brand}`
        : "",
      Location: "",
      "List Date": `${
        listDate.getMonth() + 1
      }/${listDate.getDate()}/${listDate.getFullYear()}`,
      "Platform Listing Id": source.ItemID,
      QuantityNet: quantity,
    };
  },
  poshmarkSingleInventoryTransform: (source: any) => {
    const mrg_map = source.mrg_map || source;
    return {
      "Item Title": mrg_map.item_title,
      SKU: mrg_map.sku,
      Department: mrg_map.department,
      Category: mrg_map.category,
      "Sub-Category": mrg_map.sub_category,
      "Purchase Date": mrg_map.purchase_date.split("T")[0].replace(/-/g, "/"),
      "Purchase Price": mrg_map.purchase_price,
      "Platforms Listed": mrg_map.platforms_listed || "Poshmark",
      Notes: mrg_map.notes,
      Brand: mrg_map.brand,
      Location: mrg_map.location,
      "List Date": mrg_map.list_date.split("T")[0].replace(/-/g, "/"),
      "Platform Listing Id": mrg_map.listing_id,
      QuantityNet: mrg_map.quantity,
    };
  },
  mercariSingleInventoryTransform: (mrg_map: any) => {
    return {
      "Item Title": mrg_map.item_title,
      SKU: mrg_map.sku,
      Department: mrg_map.department,
      Category: mrg_map.category,
      "Sub-Category": mrg_map.sub_category,
      "Purchase Date": mrg_map.purchase_date.split("T")[0].replace(/-/g, "/"),
      "Purchase Price": mrg_map.purchase_price,
      "Platforms Listed": mrg_map.platforms_listed || "Mercari",
      Notes: mrg_map.notes,
      Brand: mrg_map.brand,
      Location: mrg_map.location,
      "List Date": mrg_map.list_date.split("T")[0].replace(/-/g, "/"),
      "Platform Listing Id": mrg_map.listing_id,
      QuantityNet: mrg_map.quantity,
    };
  },
  etsySingleInventoryTransform: (etsyItem: any) => {
    return {
      "Item Title": etsyItem.title,
      SKU: etsyItem.skus.toString(),
      Department: etsyItem.department,
      Category: etsyItem.category,
      "Sub-Category": etsyItem.sub_category,
      "Purchase Date": createEtsyDate(etsyItem["original_creation_timestamp"]),
      "Purchase Price": 0,
      "Platforms Listed": "Etsy",
      Notes: "",
      Brand: "",
      Location: "",
      "List Date": createEtsyDate(etsyItem["original_creation_timestamp"]),
      "Platform Listing Id": etsyItem.listing_id,
      QuantityNet: etsyItem.quantity,
    };
  },
  amazonSingleInventoryTransform: (source: any) => {
    const mrg_map = source.mrg_map || source;
    return {
      "Item Title": mrg_map.item_title,
      SKU: mrg_map.sku,
      Department: mrg_map.department,
      Category: mrg_map.category,
      "Sub-Category": mrg_map.sub_category,
      "Purchase Date": mrg_map.purchase_date.split("T")[0].replace(/-/g, "/"),
      "Purchase Price": mrg_map.purchase_price,
      "Platforms Listed": mrg_map.platforms_listed || "Amazon",
      Notes: mrg_map.notes,
      Brand: mrg_map.brand,
      Location: mrg_map.location,
      "List Date": mrg_map.list_date.split("T")[0].replace(/-/g, "/"),
      "Platform Listing Id": mrg_map.listing_id,
      QuantityNet: mrg_map.quantity,
    };
  },
  ebaySalesDirectImport: (source: any, index: number, allSources: any[]) => {
    const shippingItems = allSources.filter(
      (item) =>
        item.transactionType === "SHIPPING_LABEL" &&
        item.orderId === source.orderId
    );
    const extraFeeItems = allSources.filter(
      (item) =>
        item.transactionType === "NON_SALE_CHARGE" &&
        item.references &&
        item.references.length &&
        item.bookingEntry === "DEBIT" &&
        item.references.find(
          (ref) =>
            ref.referenceType === "ORDER_ID" &&
            ref.referenceId === source.orderId
        )
    );
    const extraTransactionIds: string[] = [];
    const shippingCost = shippingItems.reduce((result, item) => {
      extraTransactionIds.push(item.transactionId);
      if (item.bookingEntry === "DEBIT") {
        return result - +item.amount.value;
      }
    }, 0);
    const extraFees = extraFeeItems.reduce((result, item) => {
      extraTransactionIds.push(item.transactionId);
      if (item.bookingEntry === "DEBIT") {
        return result - +item.amount.value;
      }
    }, 0);
    if (source.transactionType !== "SALE" || !+source.amount.value) {
      return [];
    }
    const saleDate = new Date(source.transactionDate);
    const listDate = source.list_date ? new Date(source.list_date) : "";
    const formattedListDate = listDate
      ? `${
          listDate.getMonth() + 1
        }/${listDate.getDate()}/${listDate.getFullYear()}`
      : "";
    const mapped = {
      "Sale Date": `${
        saleDate.getMonth() + 1
      }/${saleDate.getDate()}/${saleDate.getFullYear()}`,
      "Sale State": "",
      "Item Title": source.item_title || source.orderId,
      SKU: source.sku || "",
      "Sale Price": +(source.totalFeeBasisAmount?.value || 0),
      "Sales Tax": 0,
      "Liable to Pay": false,
      "Transaction Fees": +(source.totalFeeAmount?.value || 0),
      "Shipping Costs": 0,
      "Shipping Costs (Analytics Only)": Math.abs(shippingCost) || 0,
      "Other Costs (Analytics Only)": Math.abs(extraFees) || 0,
      "Purchase Date": formattedListDate || janFirst,
      "Purchase Price": 0,
      "Sale Platform": "eBay",
      Notes: ebayPlaceholderMessage,
      "List Date": formattedListDate,
      Department: "",
      Category: "",
      "Sub-Category": "",
      Brand: "",
      Location: "",
      "Platform Sale Id": source.orderId,
      "Extra Transaction Ids": extraTransactionIds.join(","),
    };
    if (source.order_details) {
      mapped["Sale State"] =
        source.order_details.ShippingAddress?.StateOrProvince;
      mapped["Sales Tax"] =
        source.order_details.TransactionArray?.Transaction
          ?.eBayCollectAndRemitTaxes?.TotalTaxAmount || 0;
    }
    if (source.Quantity > 1) {
      mapped.Notes = `Sold ${source["Quantity"]} units. ` + mapped.Notes;
    }
    return [mapped];
  },
  ebaySalesReturns: (source: any, index: number, allSources: any[]) => {
    if (source.transactionType !== "REFUND" || !source.amount.value) {
      return [];
    }
    let adFees = 0;
    const extraFeeItems = allSources.filter(
      (item) =>
        item.transactionType === "NON_SALE_CHARGE" &&
        item.references &&
        item.references.length &&
        item.bookingEntry === "CREDIT" &&
        item.references.find(
          (ref) =>
            ref.referenceType === "ORDER_ID" &&
            ref.referenceId === source.orderId
        )
    );

    const extraFees = extraFeeItems.reduce((result, item) => {
      if (item.feeType === "AD_FEE") {
        adFees += item.amount.value;
        return result;
      }
      return result + item.amount.value;
    }, 0);
    const references = source.references || [];
    const reference = references.find(
      (ref) => ref.referenceType === "RETURN_ID"
    );
    const returnId = reference?.referenceId || "";
    const saleDate = new Date(source.transactionDate);
    const mapped = {
      "Return Date": `${
        saleDate.getMonth() + 1
      }/${saleDate.getDate()}/${saleDate.getFullYear()}`,
      "Order Number": source.orderId,
      "Return ID": returnId,
      "Return Amount": +(
        source.totalFeeBasisAmount?.value || source.amount.value
      ),
      "Fees Returned": +(source.totalFeeAmount?.value || 0),
      "Ad Fees Returned": Math.abs(adFees) || 0,
    };
    return [mapped];
  },
  ebaySalesExtraRows: (source: any, index: number, allSources: any[]) => {
    let transactionDate = new Date();
    let formattedTransactionDate = "";
    switch (source.transactionType) {
      case "SHIPPING_LABEL":
        transactionDate = new Date(source.transactionDate);
        formattedTransactionDate = `${
          transactionDate.getMonth() + 1
        }/${transactionDate.getDate()}/${transactionDate.getFullYear()}`;
        let expenseRow = {
          date: new Date(formattedTransactionDate),
          account: "Shipping Fees",
          amount:
            source.bookingEntry === "CREDIT"
              ? -source.amount.value
              : +source.amount.value,
          transactionId: source.transactionId,
          description:
            source.transactionId + " " + source.transactionMemo || "",
          transactionType: "Shipping Label",
          ...(source.orderId && { orderId: source.orderId }),
        };
        return [expenseRow];
      case "ADJUSTMENT":
        transactionDate = new Date(source.transactionDate);
        formattedTransactionDate = `${
          transactionDate.getMonth() + 1
        }/${transactionDate.getDate()}/${transactionDate.getFullYear()}`;
        return [
          {
            date: new Date(formattedTransactionDate),
            account: "Listing/Platform Fees",
            amount:
              source.bookingEntry === "CREDIT"
                ? -source.amount.value
                : +source.amount.value,
            transactionId: source.transactionId,
            description:
              source.transactionId + " " + source.transactionMemo || "",
            transactionType: "Adjustment",
          },
        ];
      case "NON_SALE_CHARGE":
        const foundRef =
          source.references && source.references.length
            ? source.references.find((ref) => ref.referenceType === "ORDER_ID")
            : null;
        const foundRefItem =
          source.references && source.references.length
            ? source.references.find((ref) => ref.referenceType === "ITEM_ID")
            : null;
        const refOrderId = foundRef ? foundRef.referenceId : "";
        const refItemId = foundRefItem ? foundRefItem.referenceId : "";
        transactionDate = new Date(source.transactionDate);
        formattedTransactionDate = `${
          transactionDate.getMonth() + 1
        }/${transactionDate.getDate()}/${transactionDate.getFullYear()}`;
        return [
          {
            date: new Date(formattedTransactionDate),
            account: "Listing/Platform Fees",
            amount:
              source.bookingEntry === "CREDIT"
                ? -source.amount.value
                : +source.amount.value,
            transactionId: source.transactionId,
            description: source.transactionId + " " + source.feeType || "",
            transactionType: "Other Fees",
            ...(foundRef && { orderId: refOrderId }),
            ...(foundRefItem && { itemId: refItemId }),
          },
        ];
      default:
        return [];
    }
  },
  mrgSales: (source) => source,
};

export const getUpdatedEbayOrders = (allSales, newRows) => {
  console.log({ newRows });
  const updates: any = {};
  const now = Date.now();
  const lastWeekSales = allSales.filter((sale) => {
    if (!sale.sale_date || !sale.transaction_id) {
      return false;
    }
    const then = sale.sale_date.valueOf();
    return now - then < 7 * 24 * 60 * 60 * 1000; // one week
  });
  newRows.forEach((row: any) => {
    if (
      !row.amount.value ||
      (row.transactionType !== "SHIPPING_LABEL" &&
        (row.transactionType !== "NON_SALE_CHARGE" ||
          !row?.references?.length ||
          row.bookingEntry !== "DEBIT"))
    ) {
      return;
    }
    const matchedSale = lastWeekSales.find((sale) => {
      if (row.transactionType === "SHIPPING_LABEL") {
        return sale.transaction_id === row.orderId;
      }
      return !!row.references.find(
        (ref) =>
          ref.referenceType === "ORDER_ID" &&
          ref.referenceId === sale.transaction_id
      );
    });
    if (matchedSale) {
      const saleTransactionIds = (
        matchedSale.extra_transaction_ids || ""
      ).split(",");
      console.log({ matchedSale, row, saleTransactionIds });
      if (!new Set(saleTransactionIds).has(row.transactionId)) {
        const saleId = matchedSale.id;
        let updatedSale;
        if (updates[saleId]) {
          updatedSale = updates[saleId];
          updatedSale.extra_transaction_ids =
            updatedSale.extra_transaction_ids + `,${row.transactionId}`;
        } else {
          updatedSale = {
            ...matchedSale,
            extra_transaction_ids: [
              ...saleTransactionIds,
              row.transactionId,
            ].join(","),
          };
          updates[saleId] = updatedSale;
        }
        if (row.transactionType === "SHIPPING_LABEL") {
          if (row.bookingEntry === "DEBIT") {
            updatedSale.shipping_cost += +row.amount.value;
          } else {
            updatedSale.shipping_cost -= +row.amount.value;
          }
        } else {
          updatedSale.transaction_fees += +row.amount.value;
        }
      }
    }
  });
  return Object.values(updates);
};

export const getExtraEbayTransactions = (rows) =>
  Papa.unparse(
    rows.filter((row) => {
      if (row.Type === "Order") {
        return false;
      }
      if (row.Type === "Shipping label") {
        const matchingOrder = rows.find(
          (item) =>
            item.Type === "Order" &&
            item["Order number"] === row["Order number"]
        );
        if (matchingOrder) {
          return false;
        }
      }
      return true;
    }),
    { columns: ebayTransactionHeaders }
  );

export function transformInput(
  key: keyof typeof transforms,
  data: any[],
  unparse = true
) {
  const transformed = data.map(transforms[key] as any).flat();
  const type = keyTypes[key];
  const mrgHeaders = type === "sales" ? mrgSalesHeaders : mrgInventoryHeaders;

  if (key === "ebaySalesDirectImport") {
    let foundUnIdedFees;
    const extraRows: any[] = data
      .map(transforms.ebaySalesExtraRows)
      .flat()
      .filter((row) => {
        if (!row.date) {
          foundUnIdedFees = true;
          return false;
        }
        return true;
      });
    let formattedExtraRows: any[] = [];
    extraRows.forEach((row) => {
      formattedExtraRows.push({
        "General Ledger Account":
          row.transactionType === "Shipping Label"
            ? "Shipping Fees"
            : "Listing/Platform Fees",
        Amount: row.amount,
        Date: row.date,
        Description: row.description,
        "Transaction Type": row.transactionType,
      });
    });
    if (foundUnIdedFees) {
      formattedExtraRows.push({
        "General Ledger Account": "Listing/Platform Fees",
        Amount: null,
        Date: "There are some fees that MRG doesn't recognize and couldn't pull with your other data, please reach out to MRG support if you'd like this information.",
        Description: null,
      });
    }
    const returns = data.map(transforms.ebaySalesReturns).flat();
    return {
      sales: Papa.unparse(transformed, { columns: mrgHeaders }),
      extra: extraRows.length
        ? Papa.unparse(formattedExtraRows, { columns: extraRowsHeaders })
        : "",
      returns: returns.length
        ? Papa.unparse(returns, { columns: returnsHeaders })
        : "",
    };
  }

  if (unparse) return Papa.unparse(transformed, { columns: mrgHeaders });
  return transformed;
}

export const preprocessInput = (
  key: keyof typeof preprocessors,
  data: string[]
) => {
  const step1Input = preprocessors[key](data);
  return step1Input.filter((row) => !!row);
};

export const transformTransaction = (source: any) => {
  return {
    account: forceNull(source["General Ledger Account"]),
    description: source["Description"],
    date: forceNull(source["Date"]),
    amount: forceNull(source["Amount"]),
  };
};
export function transformSale(source: any) {
  const user = getUserIdSync();
  return {
    brand: forceNull(source.Brand),
    category: forceNull(source.Category),
    days_on_platform: daysOnPlatform(source["Sale Date"], source["List Date"]),
    department: forceNull(source.Department),
    item_option: null,
    item_title: forceNull(source["Item Title"]),
    liable_to_pay: convertBoolean(source["Liable to Pay"]),
    list_date: forceNull(convertDate(source["List Date"])),
    location: forceNull(source.Location),
    net_profit: null,
    notes: "",
    other_business_costs: 0,
    platforms_listed: forceNull(source["Platforms Listed"]),
    purchase_date: forceNull(convertDate(source["Purchase Date"])),
    purchase_price: convertNumber(source["Purchase Price"]) || 0,
    sale_date: forceNull(convertDate(source["Sale Date"])),
    sale_platform: forceNull(source["Sale Platform"]),
    sale_price: convertNumber(source["Sale Price"]) || 0,
    sale_state: forceNull(source["Sale State"]),
    sale_tax_paid: null,
    sales_tax: convertNumber(source["Sales Tax"]) || 0,
    shipping_cost:
      convertNumber(source["Shipping Costs"]) ||
      convertNumber(source["Shipping Cost"]) ||
      0,
    shipping_cost_analytics:
      convertNumber(source["Shipping Costs (Analytics Only)"]) ||
      convertNumber(source["Shipping Costs (From Expense Detail)"]) ||
      0,
    sku: forceNull(source.SKU),
    sub_category: forceNull(source["Sub-Category"]),
    transaction_fees: convertNumber(source["Transaction Fees"]) || 0,
    other_fees:
      convertNumber(source["Other Costs (Analytics Only)"]) ||
      convertNumber(source["Other Costs (From Expense Detail)"]) ||
      0,
    transaction_id: source["Platform Sale Id"],
    extra_transaction_ids: source["Extra Transaction Ids"] || "",
    unmatched: true,
    ...(source["Transaction Id"] && {
      transaction_id: source["Transaction Id"],
    }),
    user,
  };
}

export const downloadCsv = (csvData, csvType) => {
  if (!csvData) {
    return;
  }
  const now = new Date();
  const dataURL = URL.createObjectURL(csvData);
  const downloadLink = document.createElement("a");
  downloadLink.href = dataURL;
  downloadLink.download = `${csvType}_${
    now.getMonth() + 1
  }-${now.getDate()}-${now.getFullYear()}.csv`;
  downloadLink.click();
};

// Taken from InventorySyncDialog
export function createTransformInventory(user) {
  return (source) => {
    const m: any = {
      brand: forceNull(source.Brand),
      category: forceNull(source.Category),
      department: forceNull(source.Department),
      item_option: null,
      item_title: forceNull(source["Item Title"]),
      liable_to_pay: convertBoolean(source["Liable to Pay"]),
      list_date: forceNull(convertDate(source["List Date"])),
      location: forceNull(source.Location),
      notes: "",
      platforms_listed: forceNull(source["Platforms Listed"]),
      purchase_date: forceNull(convertDate(source["Purchase Date"])),
      purchase_price: convertNumber(source["Purchase Price"]) || 0,
      sku: forceNull(source.SKU),
      sub_category: forceNull(source["Sub-Category"]),
      listing_id: source["Platform Listing Id"],
      quantity: convertNumber(source["QuantityNet"]) || 0,
      user,
    };

    if (source.downloadTimestamp)
      m.download_timestamp = source.downloadTimestamp;

    return m;
  };
}
