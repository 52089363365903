import React, { type Dispatch, type SetStateAction } from "react";

import type { EtsyIntegration } from "src/interfaces/plaidIntegration.interface";

import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";
import useIsRunning from "../useIsRunning";
import type { DialogState } from "../types";
import {
  etsyDeleteIntegration,
  etsyFetchListings,
  etsyFetchTransactions,
  etsyIntegrationSyncChange,
  etsySyncApi,
} from "src/apiService/modules/etsy";
import { DataType } from "../../GetStarted/ImportDialog";
import { isEtsyAllowedToDirectImportSelector } from "../../../store/uploads/selector";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// fix-vim-highlight = }

const IsRunningThreshold = 10 * 60 * 1000; // 10 min

function EtsyIntegrationTableRow({
  integration,
  setDialog,
  hideInventory,
}: {
  integration: EtsyIntegration;
  setDialog: Dispatch<SetStateAction<DialogState>>;
  hideInventory: boolean;
}) {
  const isRunning = useIsRunning(
    integration.salesIsRunning,
    integration.inventoryIsRunning,
    IsRunningThreshold
  );

  // TODO - Placeholder for Transaction Sync/Import ticket.
  const fetchTransactions = async () => {
    const response = await etsyFetchTransactions();
  };

  function usePreventImportEtsy(dataType: DataType) {
    return !useSelector((state: any) =>
      isEtsyAllowedToDirectImportSelector(state, dataType)
    );
  }

  return (
    <PlatformIntegrationsTableRow
      key={integration.id}
      name={integration.username || "Etsy"}
      username={integration.username}
      platform="Etsy"
      isRunning={isRunning}
      sync={integration.sync}
      inventory={integration.sync && integration.inventory !== false}
      onPullDataNow={async () => {
        await etsySyncApi(integration.id);
        toast.success("Request received. Your imports are processing.");
      }}
      onImport={() => {
        setDialog({
          type: "import",
          props: {
            integrationId: integration.id,
            fetchListings: async () => {
              const resp = await etsyFetchListings();
              if (!resp.data) {
                return { success: true };
              }
            },
            fetchTransactions,
            providerName: "Etsy",
            usePreventImport: usePreventImportEtsy,
          },
        });
      }}
      onSyncChanged={async (sync, inventory) => {
        await etsyIntegrationSyncChange(integration.id, sync, inventory);
        if ((inventory === undefined || inventory) && sync)
          await etsySyncApi(integration.id);
      }}
      hideInventory={hideInventory}
      onDelete={() =>
        setDialog({
          type: "delete",
          props: {
            integration,
            deleteIntegration: etsyDeleteIntegration,
          },
        })
      }
    />
  );
}

export default EtsyIntegrationTableRow;
