import type { Sale } from "src/interfaces/sale.interface";
import type { Inventory } from "src/interfaces/inventory.interface";
import { calculateGrossProfit, daysOnPlatform, Allocator } from "../utils";

export function mapInventory(product): Omit<Inventory, "user" | "id"> {
  return {
    listing_id: product.itemId,
    item_title: product.name || "",
    department: product.itemCategoryHierarchy?.[0]?.name || "",
    category:
      product.itemCategoryHierarchy?.[1]?.name || product.categoryTitle || "",
    sub_category: product.itemCategoryHierarchy?.[2]?.name || "",
    brand: product.brand?.brandName || "",
    list_date: new Date(product.created * 1000),
    purchase_date: new Date(product.created * 1000),
    platforms_listed: ["Mercari"],
    location: "",
    notes: "",
    sku: "",
    quantity: 1,
    purchase_price: 0,
  };
}

function parseFormattedPrice(formattedPrice: any): number {
  if (!formattedPrice) return 0;
  return parseFloat(formattedPrice.toString().replace(/\$|\s/g, "")) || 0;
}

function parseSoldTimeText(soldTimeText: string): Date {
  if (soldTimeText) {
    const match = soldTimeText.match(/Sold on (\d{2})\/(\d{2})\/(\d+)/);
    if (match) {
      const month = parseInt(match[1], 10);
      const date = parseInt(match[2], 10);
      let year = parseInt(match[3], 10);
      if (year < 2000) year += 2000;
      return new Date(
        [year, month, date].map((n) => `${n}`.padStart(2, "0")).join("-") +
          "T12:00:00.000Z"
      );
    }
  }
  return new Date();
}

type MappedSale = Omit<Sale, "id" | "user"> & {
  sale_price_check_error?: boolean;
};

export function mapSale(mercari): MappedSale[] {
  const summaryContent = mercari.orderStatus.componentContents.find(
    (component) =>
      component.type === "summaryContent" && component.summaryContent
  )?.summaryContent;
  const tax = summaryContent.orderItems.find((i) => i.title === "Tax");
  const shippingContent = mercari.orderStatus.componentContents.find(
    (component) =>
      component.type === "shippingContent" && component.shippingContent
  )?.shippingContent;

  const soldTime = parseSoldTimeText(summaryContent.soldTimeText);

  const sale: MappedSale = {
    transaction_id: mercari.id,
    item_title: mercari.name,
    sale_price: (mercari.price || 0) / 100,
    transaction_fees: Math.abs(
      ["selling fee", "processing fee"].reduce((tf, text) => {
        const fee = summaryContent.orderItems.find(
          (i) => i.title?.toLowerCase() === text
        );
        return tf + parseFormattedPrice(fee?.formattedPrice);
      }, 0)
    ),
    sales_tax: parseFormattedPrice(tax?.formattedPrice),
    shipping_cost: Math.abs(
      ["shipping surcharge", "shipping fee"].reduce((tf, text) => {
        const fee = summaryContent.orderItems.find(
          (i) => i.title?.toLowerCase() === text
        );
        return tf + parseFormattedPrice(fee?.formattedPrice);
      }, 0)
    ),
    sale_state:
      shippingContent?.arrivalAddress?.address?.stateAbbreviation || "",
    sale_date: soldTime,
    department: mercari.product.itemCategoryHierarchy?.[0]?.name || "",
    category:
      mercari.product.itemCategoryHierarchy?.[1]?.name ||
      mercari.product.categoryTitle ||
      "",
    sub_category: mercari.product.itemCategoryHierarchy?.[2]?.name || "",
    brand: mercari.product.brand?.brandName || "",
    list_date: new Date(mercari.product.created * 1000),
    purchase_date: new Date(mercari.product.created * 1000),
    location: "",
    notes: "",
    sku: "",
    purchase_price: 0,
    sale_platform: "Mercari",
    platforms_listed: ["Mercari"],

    extra_shipping_cost: 0,
    extra_transaction_fees: 0,
    shipping_cost_analytics: 0,
    other_fees: 0,
    liable_to_pay: false,
    days_on_platform: 0,
    gross_profit: 0,
    other_business_costs: 0,
    net_profit: 0,
    estimated_income_tax: 0,
    sale_tax_paid: 0,
  };

  sale.days_on_platform = daysOnPlatform(sale.sale_date, sale.list_date) || 0;
  sale.gross_profit = calculateGrossProfit(sale);
  sale.net_profit =
    sale.gross_profit - sale.estimated_income_tax - sale.other_business_costs;

  const salePriceCheck =
    sale.sale_price - sale.transaction_fees - sale.shipping_cost;
  const youMade = summaryContent.orderItems.find((i) => i.title === "You made");

  if (
    salePriceCheck.toFixed(2) !==
    (parseFormattedPrice(youMade?.formattedPrice) || 0).toFixed(2)
  ) {
    sale.sale_price_check_error = true;
    sale.notes =
      "There was an error importing some of your Mercari data. Please reach out to support@myresellergenie.com so we can help resolve this issue.";
  }

  if (mercari.itemType !== "bundle") return [sale];

  const orderContent = mercari.orderStatus.componentContents.find(
    (component) => component.type === "orderContent" && component.orderContent
  )?.orderContent;

  if (!orderContent?.items?.length) return [sale];

  const total = orderContent.items.reduce((acc, item) => acc + item.price, 0);
  const length = orderContent.items.length;
  const salePriceAllocator = new Allocator(sale.sale_price, total, length, 2);
  const transactionFeesAllocator = new Allocator(
    sale.transaction_fees,
    total,
    length,
    2
  );
  const salesTaxAllocator = new Allocator(sale.sales_tax, total, length, 2);
  const shippingCostAllocator = new Allocator(
    sale.shipping_cost,
    total,
    length,
    2
  );

  return orderContent.items.map((item) => {
    const s = {
      ...sale,
      item_title: item.name,
      sale_price: salePriceAllocator.getAllocation(item.price),
      transaction_fees: transactionFeesAllocator.getAllocation(item.price),
      sales_tax: salesTaxAllocator.getAllocation(item.price),
      shipping_cost: shippingCostAllocator.getAllocation(item.price),
    };
    const product = mercari.products?.find((p) => p.itemId === item.id);
    if (product) {
      s.department = product.itemCategoryHierarchy?.[0]?.name || "";
      s.category =
        product.itemCategoryHierarchy?.[1]?.name || product.categoryTitle || "";
      s.sub_category = product.itemCategoryHierarchy?.[2]?.name || "";
      s.brand = product.brand?.brandName || "";
      s.list_date = new Date(product.created * 1000);
      s.purchase_date = new Date(product.created * 1000);
    }

    s.days_on_platform = daysOnPlatform(s.sale_date, s.list_date) || 0;
    s.gross_profit = calculateGrossProfit(s);
    s.net_profit =
      s.gross_profit - s.estimated_income_tax - s.other_business_costs;

    return s;
  });
}
