import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import MonthYear from "src/interfaces/monthYear.interface";
import {
  fetchNewEbayListingsApi,
  fetchNewEbayTransactionsApi,
} from "src/apiService/modules/ebay";
import { isAllowedToDirectImportSelector } from "src/store/uploads/selector";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import LoadableButton from "src/components/LoadableButton";
import { updatePlaidIntegrationItem as updateIntegrationItem } from "src/store/plaidIntegration/actions";

import MonthYearField, { CurrentMonthYear } from "./MonthYearField";

const MercariNotice =
  "The Mercari integration is still in beta testing. If you don’t wish to use this feature, click ‘Continue Set-up’. You can still upload reports from Mercari or enter sales manually.";

export type DataType = "Inventory" | "Sales";

interface ImportDialogContentProps {
  onClose: () => void;
  integrationId: string;
  fetchListings?: (integrationId: string) => Promise<{ success?: boolean }>;
  fetchTransactions?: (
    integrationId: string,
    start: MonthYear,
    end: MonthYear
  ) => Promise<{ success?: boolean }>;
  providerName?: string;
  usePreventImport?: (dataType: DataType) => boolean;
  DialogTitle?: React.ComponentType;
  actions?: React.ReactElement | null;
  disableInventory?: boolean;
  disableSales?: boolean;
}

function usePreventImportEBay(dataType: DataType) {
  return !useSelector((state: any) =>
    isAllowedToDirectImportSelector(state, dataType)
  );
}

export function ImportDialogContent({
  onClose,
  integrationId,
  fetchListings = fetchNewEbayListingsApi,
  fetchTransactions = fetchNewEbayTransactionsApi,
  providerName = "eBay",
  usePreventImport = usePreventImportEBay,
  DialogTitle = MuiDialogTitle,
  disableInventory: _disableInventory = false,
  disableSales = false,
  actions = null,
}: ImportDialogContentProps) {
  const dispatch = useDispatch();
  const shouldHideInventory = useSelector(
    (s: any) => userGetInventoryTypeSelector(s) === "cash"
  );
  const disableInventory = _disableInventory || shouldHideInventory;
  const [_dataType, setDataType] = useState<DataType>("Inventory");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({
    start: CurrentMonthYear,
    end: CurrentMonthYear,
  });
  const _preventImport = usePreventImport(
    disableInventory ? "Sales" : _dataType
  );
  const preventImport =
    disableInventory && disableSales ? false : _preventImport;
  const dataType = disableInventory ? (disableSales ? "" : "Sales") : _dataType;

  const handleClick = async () => {
    setLoading(true);
    try {
      let success = false;
      dispatch(updateIntegrationItem({ id: integrationId, loading: dataType }));
      if (dataType === "Sales")
        success =
          (await fetchTransactions(integrationId, date.start, date.end))
            ?.success || false;
      else success = (await fetchListings(integrationId))?.success || false;

      // The backend takes a while to set the `salesIsRunning` flag, we make an optimistic update
      if (providerName === "eBay") {
        dispatch(
          updateIntegrationItem({
            id: integrationId,
            loading: undefined,
            [dataType === "Sales" ? "salesIsRunning" : "inventoryIsRunning"]:
              success,
          })
        );
      }

      if (success) {
        toast.success("Request received. Your import is processing.");
      } else {
        toast.error("There has been an error processing the import.");
      }
      onClose();
    } catch (e) {
      toast.error(`Error: ${(e as Error).message}`);
      setLoading(false);
    }
  };

  const selectDataType = (e) => setDataType(e.target.value);

  const startIsGreaterThanNow =
    dataType === "Sales" &&
    (CurrentMonthYear.year < date.start.year ||
      (CurrentMonthYear.year === date.start.year &&
        CurrentMonthYear.month < date.start.month));
  const startIsGreaterThanEnd =
    dataType === "Sales" &&
    (date.end.year < date.start.year ||
      (date.end.year === date.start.year && date.end.month < date.start.month));

  const isMercari = providerName?.toLowerCase() === "mercari";

  return (
    <>
      <DialogTitle>Import from {providerName}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          MRG has successfully connected to your {providerName} account.
          {isMercari ? (
            <Tooltip title={MercariNotice}>
              <span>*</span>
            </Tooltip>
          ) : (
            ""
          )}
        </Typography>
        <Typography variant="body1" paragraph>
          You may now import your data into My Reseller Genie.
        </Typography>
        <Select
          onChange={selectDataType}
          variant="outlined"
          value={dataType}
          disabled={loading}
        >
          {disableInventory ? null : (
            <MenuItem value="Inventory">Inventory</MenuItem>
          )}
          {disableSales ? null : <MenuItem value="Sales">Sales</MenuItem>}
        </Select>
        {dataType === "Sales" && (
          <Box mt={2}>
            <MonthYearField
              disabled={loading}
              value={date.start}
              onChange={(f) => {
                setDate((d) => ({
                  ...d,
                  start: f(d.start),
                }));
              }}
              error={
                startIsGreaterThanNow
                  ? "The starting date can’t be in the future."
                  : undefined
              }
            >
              Enter the starting date for the sales you want to import.
            </MonthYearField>
            <MonthYearField
              disabled={loading}
              value={date.end}
              onChange={(f) => {
                setDate((d) => ({
                  ...d,
                  end: f(d.end),
                }));
              }}
              error={
                startIsGreaterThanEnd
                  ? "The ending date must be after the starting date."
                  : undefined
              }
            >
              Enter the ending date for the sales you want to import.
            </MonthYearField>
          </Box>
        )}
        {preventImport && !loading ? (
          <Typography className="my-3" variant="body1" style={{ color: "red" }}>
            Your current plan doesn't allow for multiple {dataType} imports from{" "}
            {providerName}. You can upgrade to Ultimate if you'd like to do
            this, or delete your last import under "Uploads" in the Profile
            menu.
          </Typography>
        ) : null}
        {isMercari ? (
          <Box mt={2} mb={-1}>
            <Typography variant="caption" display="block">
              * {MercariNotice}
            </Typography>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {actions}
        <LoadableButton
          disabled={
            preventImport || startIsGreaterThanEnd || startIsGreaterThanNow
          }
          color="primary"
          variant="contained"
          onClick={handleClick}
          loading={loading}
        >
          Import {dataType}
        </LoadableButton>
      </DialogActions>
    </>
  );
}

export interface ImportDialogProps {
  integrationId?: string;
  onClose: () => void;
  fetchListings?: ImportDialogContentProps["fetchListings"];
  fetchTransactions?: ImportDialogContentProps["fetchTransactions"];
  providerName?: ImportDialogContentProps["providerName"];
  usePreventImport?: ImportDialogContentProps["usePreventImport"];
}

function ImportDialog({
  integrationId,
  onClose,
  fetchListings,
  fetchTransactions,
  providerName,
  usePreventImport,
}: ImportDialogProps) {
  return (
    <Dialog open={!!integrationId} onClose={onClose} fullWidth maxWidth="sm">
      {!!integrationId && (
        <ImportDialogContent
          onClose={onClose}
          integrationId={integrationId}
          fetchListings={fetchListings}
          fetchTransactions={fetchTransactions}
          providerName={providerName}
          usePreventImport={usePreventImport}
        />
      )}
    </Dialog>
  );
}

export default ImportDialog;
