import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { loadNewSyncSales } from "src/store/sale/actions";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { markErrorAlertViewed } from "src/apiService";
import type {
  Alert,
  AlertEBay,
  AlertEtsy,
} from "src/interfaces/alert.interface";
import { getEBayOAuthUrl } from "src/apiService/modules/ebay";

import WalkThrough from "./WalkThroughNotificationsDialog";
import getAlertSelector from "./getAlertSelector";
import { etsyRequestAuthorizationCode } from "../../apiService/modules/etsy";

export interface NotificationsDialogProps {
  open: boolean;
  onClose: () => void;
  items: Alert[];
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    selectedRow: {
      backgroundColor: "#ccccff",
    },
    notificationItem: {
      border: "1px solid #eeeeff",
      minHeight: 60,
      "&:hover": {
        backgroundColor: "#eeeeff",
        cursor: "pointer",
      },
    },
  })
);

function EBayRefreshTokenDialogContent({ integrationId }) {
  useEffect(() => {
    if (!integrationId) return;
    let cancel = false;

    (async () => {
      const params: { integrationId: string; integrationName?: string } = {
        integrationId,
      };

      if (integrationId === "ebay") params.integrationName = "eBay";
      const {
        data: { url },
      } = await getEBayOAuthUrl(params);

      if (cancel) return;

      (window.location as any) = url;
    })();

    return () => {
      cancel = true;
    };
  }, [integrationId]);

  return (
    <DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <CircularProgress />
      </Box>
    </DialogContent>
  );
}

function EBayRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh eBay integration</DialogTitle>
      <EBayRefreshTokenDialogContent integrationId={integrationId} />
    </Dialog>
  );
}

function EtsyRefreshTokenDialogContent({ integrationId }) {
  useEffect(() => {
    if (!integrationId) return;
    let cancel = false;

    (async () => {
      const authResponse = await etsyRequestAuthorizationCode();

      if (cancel) return;
      window.location.href = authResponse.data.url;
    })();

    return () => {
      cancel = true;
    };
  }, [integrationId]);

  return (
    <DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <CircularProgress />
      </Box>
    </DialogContent>
  );
}

function EtsyRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh Etsy integration</DialogTitle>
      <EtsyRefreshTokenDialogContent integrationId={integrationId} />
    </Dialog>
  );
}

function NotificationsDialogContent({
  onClose,
  items,
}: Omit<NotificationsDialogProps, "open">) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alertDialog, setAlertDialog] = useState<{
    type: "ebay" | "amazon" | "etsy";
    integrationId: string;
  }>();

  const handleClick = (item: Alert) => {
    switch (item.type) {
      case "plaid_error":
        dispatch(setActiveDialog("update_expense_account"));
        onClose();
        break;

      case "error":
        markErrorAlertViewed(item.id);
        onClose();
        break;

      case "returns":
        dispatch(setActiveDialog("returns_review"));
        onClose();
        break;

      case "inventory":
        dispatch(setActiveDialog("inventory_sync"));
        onClose();
        break;

      case "sales":
        dispatch(loadNewSyncSales());
        dispatch(setActiveDialog("match_sales"));
        onClose();
        break;

      case "review_sales":
        dispatch(setActiveDialog("review_sales"));
        onClose();
        break;

      case "expenses":
        dispatch(setActiveDialog("categorize_expenses"));
        onClose();
        break;

      case "cash_activities":
        dispatch(setActiveDialog("cash_activities"));
        onClose();
        break;

      case "ebay_error":
        setAlertDialog({
          type: "ebay",
          integrationId: (item as AlertEBay).integrationId,
        });
        break;

      case "etsy_error":
        setAlertDialog({
          type: "etsy",
          integrationId: (item as AlertEtsy).integrationId,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <DialogTitle className="relative">Notifications</DialogTitle>
      <DialogContent>
        {items.length ? (
          <List>
            {items.map((item) => (
              <ListItem
                key={JSON.stringify(item)}
                onClick={() => handleClick(item)}
                className={clsx(
                  classes.notificationItem,
                  getAlertSelector(item.type)
                )}
              >
                {item.message}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="h6" className="mb-4">
            No current notifications.
          </Typography>
        )}
      </DialogContent>
      <EBayRefreshTokenDialog
        integrationId={
          alertDialog?.type === "ebay" ? alertDialog.integrationId : undefined
        }
        onClose={() => setAlertDialog(undefined)}
      />
      <EtsyRefreshTokenDialog
        integrationId={
          alertDialog?.type === "etsy" ? alertDialog.integrationId : undefined
        }
        onClose={() => setAlertDialog(undefined)}
      />
    </>
  );
}

function NotificationsDialog({
  open,
  onClose,
  items,
}: NotificationsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <NotificationsDialogContent onClose={onClose} items={items} />
      <WalkThrough alerts={items} />
    </Dialog>
  );
}

export default NotificationsDialog;
