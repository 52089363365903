import { createSelector } from "reselect";
import {
  transforms,
  createTransformInventory,
} from "src/utils/uploadTransform";
import { AppState } from "..";
// import { UploadState } from "../../interfaces/uploadState.interface";
import { userIdSelector, selectUser } from "../system/selector";
import { getInventory } from "src/store/inventory/selector";
import { Inventory } from "src/interfaces/inventory.interface";

const getUploadState = (state: AppState) => state.uploads;

export const getUploads = createSelector(
  getUploadState,
  (state) => state.items
);
export const getDownloads = createSelector(
  getUploadState,
  (state) => state.downloads
);
export const getNewSyncSalesDownloads = createSelector(
  getDownloads,
  (downloads) =>
    downloads.filter(
      (item) => item.new && !!item.day && item.type === "ebaySales"
    )
);
export const getNewSyncInventoryDownloads = createSelector(
  getDownloads,
  (downloads) =>
    downloads.filter(
      (item) =>
        item.new &&
        !!item.day &&
        (item.type === "ebayInventory" ||
          item.type === "poshmarkInventory" ||
          item.type === "mercariInventory" ||
          item.type === "etsyInventory")
    )
);
export const getNewSyncInventoryDownloadsWithData = createSelector(
  getNewSyncInventoryDownloads,
  (downloads) => {
    const d = downloads.filter((item) => item.state === "done" && item.data);
    d.sort((a, b) => a.timestamp - b.timestamp);
    return d;
  }
);

export const isNewSyncInventoryDownloadsLoading = createSelector(
  getNewSyncInventoryDownloads,
  (downloads) => downloads.some((d) => !d.state || d.state === "loading")
);

// Taken from InventorySyncDialog
export const getNewSyncInventoryDownloadsData = createSelector(
  [
    userIdSelector,
    isNewSyncInventoryDownloadsLoading,
    getNewSyncInventoryDownloadsWithData,
    getInventory,
  ],
  (uid, isLoading, downloads, inventory) => {
    if (isLoading) return [];
    const transformInventory = createTransformInventory(uid);
    return Object.values(
      downloads.reduce((result, inventoryHash) => {
        const { data: inventoryItems, timestamp } = inventoryHash;
        if (inventoryItems && Array.isArray(inventoryItems)) {
          // for each import
          for (const item of inventoryItems) {
            const listing_id = item["Platform Listing Id"];
            if (!listing_id) continue;
            if (
              // new record, add to hash
              !result[listing_id] ||
              // item already in the hash, we have duplicate
              // if current item is more recent, overwrite
              timestamp > result[listing_id].timestamp
            ) {
              result[listing_id] = { timestamp: timestamp, item: item };
            } // otherwise, discard current item
          }
        }
        return result;
      }, {})
    )
      .map((i: any) => ({ ...i.item, downloadTimestamp: i.timestamp }))
      .map(transformInventory)
      .filter(
        (newItem) =>
          !inventory.find((item) => item.listing_id === newItem.listing_id)
      );
  }
);

export const getNewSyncInventoryDownloadsDataV2 = createSelector(
  getNewSyncInventoryDownloadsData,
  (items) => {
    return items.map(transforms.ebayInventoryDirectImportV2).flat(2);
  }
);

function normalize(str: string | number): string {
  if (!str) {
    if (str === 0) return "0";
    return "";
  }

  return `${str}`
    .toLowerCase()
    .replace(/\s+/, " ")
    .replace(/[^0-9a-z ]/g, "")
    .trim();
}

export const getNewSyncInventoryDownloadsCompareData = createSelector(
  [
    getNewSyncInventoryDownloadsData,
    getInventory,
    (_, reviewAll = true) => reviewAll,
  ],
  (entries, inventory, reviewAll) => {
    const matchesTitle: { id: string; current: Inventory; new: Inventory }[] =
      [];
    const matchesSku: { id: string; current: Inventory; new: Inventory }[] = [];
    let earliestTimestamp = 0;
    const not: Inventory[] = [];
    for (const entry of entries) {
      if (!earliestTimestamp || entry.download_timestamp < earliestTimestamp)
        earliestTimestamp = entry.download_timestamp;

      const entryTitle = normalize(entry.item_title);
      const entrySku = normalize(entry.sku);
      const itemsTitle = inventory.filter(
        (item) => normalize(item.item_title) === entryTitle
      );
      const itemsSku = inventory.filter(
        (item) => entrySku && normalize(item.sku) === entrySku
      );

      if (itemsTitle.length) {
        const ids = itemsTitle.map((i) => i.id);
        ids.sort();
        const match = {
          current: {
            ...itemsTitle[0],
            quantity: itemsTitle.reduce((acc, i) => acc + (i.quantity || 1), 0),
          },
          new: {
            ...entry,
          } as unknown as Inventory,
        };

        matchesTitle.push({
          ...match,
          id: `${ids.join(":")}-${entry.listing_id}`,
        });
      } else if (itemsSku.length) {
        const ids = itemsSku.map((i) => i.id);
        ids.sort();
        const match = {
          current: {
            ...itemsSku[0],
            quantity: itemsSku.reduce((acc, i) => acc + (i.quantity || 1), 0),
          },
          new: {
            ...entry,
          } as unknown as Inventory,
        };

        matchesSku.push({
          ...match,
          id: `${ids.join(":")}-${entry.listing_id}`,
        });
      } else {
        not.push({
          ...entry,
          id: `${entry.listing_id}`,
        });
      }
    }

    if (reviewAll) {
      return { matchesTitle, matchesSku, not };
    } else {
      return {
        matchesTitle: matchesTitle.filter(
          (m: any) => m.new.download_timestamp === earliestTimestamp
        ),
        matchesSku: matchesSku.filter(
          (m: any) => m.new.download_timestamp === earliestTimestamp
        ),
        not: not.filter((n: any) => n.download_timestamp === earliestTimestamp),
      };
    }
  }
);

function createIsAllowedToDirectImportSelector({
  inventory,
  sales,
}: {
  inventory: string;
  sales: string;
}) {
  return createSelector(
    [selectUser, getUploads, (state, importType) => importType],
    (user, uploads, importType) => {
      if (user?.plan === "ultimate") return true;
      const t = importType === "Inventory" ? "inventory" : "sales";
      const key = importType === "Inventory" ? inventory : sales;
      return !uploads.some(
        (upload) => t === upload.type && upload.filename?.includes(key)
      );
    }
  );
}

export const isAllowedToDirectImportSelector =
  createIsAllowedToDirectImportSelector({
    inventory: "ebayInventory",
    sales: "ebaySales",
  });
export const isPoshmarkAllowedToDirectImportSelector =
  createIsAllowedToDirectImportSelector({
    inventory: "poshmarkInventory",
    sales: "poshmarkSales",
  });
export const isMercariAllowedToDirectImportSelector =
  createIsAllowedToDirectImportSelector({
    inventory: "mercariInventory",
    sales: "mercariSales",
  });
export const isAmazonAllowedToDirectImportSelector =
  createIsAllowedToDirectImportSelector({
    inventory: "amazonInventory",
    sales: "amazonSales",
  });
export const isEtsyAllowedToDirectImportSelector =
  createIsAllowedToDirectImportSelector({
    inventory: "etsyInventory",
    sales: "etsySales",
  });
