import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import usePrevious from "src/utils/usePrevious";

export function Content({
  platform: _platform,
  update: _update,
}: {
  platform?: string;
  update?: boolean;
}) {
  const platformMemory = usePrevious(_platform);
  const platform = _platform || platformMemory || "Poshmark";
  const updateMemory = usePrevious(_update);
  const update = _update === undefined ? updateMemory || false : _update;
  return (
    <>
      <DialogTitle>{update ? "Update" : "Add"} Extension</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To import your data from {platform}, you’ll need{" "}
          {update ? "to update" : ""} the My Reseller Genie Web Extension. Click
          below to download the extension from the Chrome Web Store. After
          installing the extension, close your browser and log back into My
          Reseller Genie.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          component="a"
          color="primary"
          autoFocus
          {...({
            href: "https://chromewebstore.google.com/detail/my-reseller-genie-web-ext/dmlldabapacmdgndabfmihemkgegcmfc",
            target: "_blank",
          } as any)}
        >
          Get Extension
        </Button>
      </DialogActions>
    </>
  );
}
function AddPoshmarkExtensionDialog({
  open,
  onClose,
  platform,
  update,
}: {
  open: boolean;
  onClose: () => void;
  platform?: string;
  update?: boolean;
}) {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Content platform={platform} update={update} />
    </Dialog>
  );
}

export default AddPoshmarkExtensionDialog;
