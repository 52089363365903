import React, { useState } from "react";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { userGetInventoryTypeSelector } from "src/store/system/selector";

import DialogTitle from "./DialogTitle";
import type Step from "./Step";
import { useIntegration } from "./MercariImport";

interface MercariImportInProcessProps {
  onClose: () => void;
  onStepChange: (step: Step) => void;
}

function MercariImportInProcess({
  onClose,
  onStepChange,
}: MercariImportInProcessProps) {
  const inventoryType = useSelector(userGetInventoryTypeSelector);
  const bag = useIntegration();
  const [memory] = useState(bag);

  // cash
  let text =
    "Congrats! Your sales import from Mercari has started. Sales imports from Mercari also import refunds and cancellations. Click continue set-up for your next steps.";
  const primaryButton = {
    label: "Continue Set-Up",
    action: "other:do-you-sell" as Step,
  };
  let secondaryButton = "";

  if (inventoryType === "accrual") {
    if (memory.hasAlreadyImportedInventory && memory.hasAlreadyImportedSales) {
      text =
        "Congrats! Your imports from Mercari have started. Click continue set-up for your next steps.";
    } else if (memory.hasAlreadyImportedInventory) {
      text =
        "Congrats! Your inventory import from Mercari has started. Would you like to import sales next?";
      primaryButton.label = "Yes";
      primaryButton.action = "mercari:import";
      secondaryButton = "No";
    } else {
      text =
        "Congrats! Your sales import from Mercari has started. Would you like to import inventory next?";
      primaryButton.label = "Yes";
      primaryButton.action = "mercari:import";
      secondaryButton = "No";
    }
  }

  return (
    <>
      <DialogTitle onClose={onClose}>Import in Process</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>{text}</DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        {secondaryButton ? (
          <Button
            onClick={() => {
              onStepChange("other:do-you-sell");
            }}
            color="primary"
            autoFocus
          >
            {secondaryButton}
          </Button>
        ) : null}
        <Button
          onClick={() => {
            onStepChange(primaryButton.action);
          }}
          color="primary"
          autoFocus
        >
          {primaryButton.label}
        </Button>
      </DialogActions>
    </>
  );
}

export default MercariImportInProcess;
