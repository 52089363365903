import { createAction } from "redux-actions";
import { getNewSyncInventoryDownloads } from "src/store/uploads/selector";
import { getJSONFileApi } from "src/apiService/modules/uploads";
import { transforms } from "src/utils/uploadTransform";

import {
  DOWNLOAD,
  UPLOAD,
  RESET,
  FS_CREATE_ITEMS,
  FS_DELETE_ITEMS,
  FS_UPDATE_ITEMS,
} from "../common";

export const reset = createAction(UPLOAD + RESET);

export const addUploadRecords = createAction(UPLOAD + FS_CREATE_ITEMS);
export const updateUploadRecords = createAction(UPLOAD + FS_UPDATE_ITEMS);
export const deleteUploadRecords = createAction(UPLOAD + FS_DELETE_ITEMS);

export const addDownloadRecords = createAction(DOWNLOAD + FS_CREATE_ITEMS);
export const updateDownloadRecords = createAction(DOWNLOAD + FS_UPDATE_ITEMS);
export const deleteDownloadRecords = createAction(DOWNLOAD + FS_DELETE_ITEMS);

export function getNewInventoryDownloads() {
  return (dispatch, getState) => {
    const downloads = getNewSyncInventoryDownloads(getState());
    const pendings = downloads.filter(
      (d) => !d.state || !["loading", "done"].includes(d.state)
    );

    if (!pendings.length) return;

    dispatch(
      updateDownloadRecords(
        pendings.map((pending) => ({
          id: pending.id,
          state: "loading",
        }))
      )
    );

    Promise.all(
      pendings.map((d) =>
        getJSONFileApi(d.filename).then((data) => {
          return {
            id: d.id,
            data: data.map
              ? data.map(
                  d.type === "poshmarkInventory"
                    ? transforms.poshmarkSingleInventoryTransform
                    : d.type === "mercariInventory"
                    ? transforms.mercariSingleInventoryTransform
                    : d.type === "amazonInventory"
                    ? transforms.amazonSingleInventoryTransform
                    : d.type === "etsyInventory"
                    ? transforms.etsySingleInventoryTransform
                    : transforms.ebaySingleInventoryTransform
                )
              : [],
            state: "done",
          };
        })
      )
    ).then((files) => dispatch(updateDownloadRecords(files)));
  };
}
